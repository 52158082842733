$bg-color: white;
$basic-dark-color: #56aa1c;
$header-color: #abcdef;
$footer-color: lighten($header-color, 10%);
$menu-color: darken($header-color, 20%);
$border-width: 4px;
$basic-spinner-dimensions: 50px;
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2;
$small-spinner-dimensions: $main-spinner-dimensions * 0.7;
$menu-shadow-size: 30px;

$hamburger-padding: 15px 0 !default;
$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

$breakpoint-mobile: 450px;
$breakpoint-tablet: 650px;

@import './layout';
@import './burger';
